import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/Layout";
import SearchEngineOptimization from "../components/SEO";
import BlogPostList from "../components/Blog/BlogPostList";
// import ButtonGhost from "../components/Button/ButtonGhost";
import GraphQLErrorList from "../components/Blog/graphql-error-list";
import {
  // filterOutDocsPublishedInTheFuture,
  // filterOutDocsWithoutSlugs,
  mapEdgesToNodes,
} from "../lib/helpers";
import CallToAction from "../components/Repeating/CTA";

const Page = (props) => {
  const { data, errors } = props;

  if (errors) {
    return (
      <Layout siteSettings={data.siteSettings} contactInfo={data.contactInfo}>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }

  const postNodes = data && data.posts && mapEdgesToNodes(data.posts);
  // .filter(filterOutDocsWithoutSlugs)
  // .filter(filterOutDocsPublishedInTheFuture);

  return (
    <Layout siteSettings={data.siteSettings} contactInfo={data.contactInfo}>
      <SearchEngineOptimization
        title="Senior Care Blog | MesaView Senior Assisted Living"
        description="Read MesaView's Senior Care Blog for great tips and advice on health and aging."
        // openGraphImage={data.openGraphImage.publicURL}
        // twitterOpenGraphImage={data.twitterOpenGraphImage.publicURL}
      />
      <section className="relative  bg-gray-50  md:bg-white ">
        <div className="container relative">
          <header className="mb-12 md:mb-18">
            {/* <h1 className="text-center text-[34px] font-normal leading-[47px]"></h1> */}
          </header>
        </div>
        <div className="container relative lg:py-4">
          <div className="">
            <div className="grid grid-cols-1 text-center md:max-w-[760px] md:gap-y-[25px] md:text-left">
              <h1 className=" font-medium text-primary-500">
                MesaView’s Senior Care Blog:<br></br>{" "}
                <small className="font-normal">
                  {" "}
                  Living, Learning & Growing
                </small>
              </h1>
            </div>
          </div>
        </div>
      </section>
      <section className="pt-10 pb-20 md:pt-12 md:pb-24">
        <div className="container">
          <div className="grid gap-y-10 md:grid-cols-3 md:gap-y-16 md:gap-x-10">
            {postNodes && postNodes.length > 0 && (
              <BlogPostList nodes={postNodes} />
            )}
          </div>
        </div>
      </section>

      <CallToAction phonenumber={data.contactInfo.phonenumber} />
    </Layout>
  );
};

export const query = graphql`
  {
    contactInfo: sanityContactinfo {
      title
      description
      phonenumber
      email
      address
      license
      bookingurl
    }
    siteSettings: sanitySiteSettings {
      title
      description
      author
      headerlogo {
        asset {
          gatsbyImageData
        }
      }
      footerlogo {
        asset {
          gatsbyImageData
        }
      }
      facebookurl
      twitterurl
      privacypolicy
    }

    openGraphImage: file(
      relativePath: { eq: "open-graph/facebook/Facebook.jpg" }
    ) {
      publicURL
    }
    twitterOpenGraphImage: file(
      relativePath: { eq: "open-graph/twitter/Twitter.jpg" }
    ) {
      publicURL
    }
    posts: allSanityBlogs(
      sort: { fields: [publishedAt], order: DESC }
      filter: { slug: { current: { ne: null } }, publishedAt: { ne: null } }
    ) {
      edges {
        node {
          id
          publishedAt
          featuredImage {
            asset {
              gatsbyImageData
            }
          }
          categories {
            _id
            title
          }
          title
          slug {
            current
          }
          _rawBody(resolveReferences: { maxDepth: 5 })
        }
      }
    }
  }
`;

export default Page;
